import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const KimlikItem = (props) => {

	const data = useStaticQuery(graphql`
    query {
      normal: file(relativePath: { eq: "kurumsal_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
	  },
      colored: file(relativePath: { eq: "kurumsal_logo_colored.png" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
	  },
    }
  `);

	let fluidData = data.normal.childImageSharp.fluid

	if (props.variant === 'colored') {
		fluidData = data.colored.childImageSharp.fluid
	}

	return (
		<li className={'p15 left col-xs-pl0 col-xs-pr0 ' + props.size}>
			<div className={'box w100 p25 bs1 left b-ra5 ' + props.backgroundClass + props.colorClass}>
				<div className={'p25 w100 left b-ra5 ' + props.borderColor} style={{ border: "dashed 1px" }}>
					<Img className="w100" imgStyle={{ maxWidth: "100%" }} alt="kurumsal_logo" fluid={fluidData} fadeIn={false} />
					<div className="info w100 left t-center fw4 mt25">
						<ul className={'w100 left mb0 mt25 pt25 fs14 ' + props.borderColor} style={{ borderTop: "1px dashed" }}>
							<li className="w100 left">
								<div className="title w100 left fw6 mb25 fs15">{props.mintitle}</div>
							</li>
							<li className="w50 left t-left">
								<span className="fw6 w100 left">Çizgi Rengi</span>
								<span className="fw4 w100 left">HEX :{props.linehex}</span>
								<span className="fw4 w100 left">RGBA :{props.linergba}</span>
								<span className="fw4 w100 left">HSLA :{props.linehsla}</span>
							</li>
							<li className="w50 left t-right">
								<span className="fw6 w100 left">Zemin Rengi</span>
								<span className="fw4 w100 left">HEX : {props.florhex}</span>
								<span className="fw4 w100 left">RGBA : {props.florrgba}</span>
								<span className="fw4 w100 left">HSLA : {props.florhsla}</span>
							</li>
							<li className={'w100 left pt15 pb15 mt25 ' + props.borderColor} style={{ borderTop: "1px dashed" }}></li>
							<li className="w100 left">
								<span className="fw6 w100 left">Font Family</span>
							</li>
							<li className="w100 left">
								<ul className="w100 left">
									<li className="w100 left fw6 fs18">Avenir Next</li>
								</ul>
							</li>
							<li className="w100 left mt25">
								<a className="" target="_blank" rel="noopener noreferrer" href={props.download}>
									<i className={'icon icon-download2 fs22 lh22 ' + props.iconColor}></i>
								</a>
							</li>
						</ul>
					</div>
				</div>

			</div>
		</li>
	)
}
export default KimlikItem